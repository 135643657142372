
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { contentWidthFluid, subheaderDisplay } from "@/core/helpers/config";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import { ArrowLeft } from "@element-plus/icons-vue";

export default defineComponent({
  name: "iam-page",
  components: {
    KTToolbar,
  },
  props: {
    showBack: Boolean,
  },
  setup() {
    const store = useStore();

    return {
      pageTitle: computed(() => store.getters.pageTitle),
      breadcrumbs: computed(() => store.getters.pageBreadcrumbPath),
      subheaderDisplay,
      contentWidthFluid,
      ArrowLeft,
    };
  },
});
