
import { toolbarWidthFluid } from "@/core/helpers/config";
import { PageBreadcrumb } from "@/store/modules/BreadcrumbsModule";
import { defineComponent } from "vue";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Object as () => Array<PageBreadcrumb>,
    title: String,
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
