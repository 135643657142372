import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_2 = { class: "d-flex align-items-center py-1" }
const _hoisted_3 = { class: "post d-flex flex-column-fluid" }
const _hoisted_4 = {
  id: "kt_content_container",
  class: "container-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.subheaderDisplay)
      ? (_openBlock(), _createBlock(_component_KTToolbar, {
          key: 0,
          breadcrumbs: _ctx.breadcrumbs,
          title: _ctx.pageTitle
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.showBack)
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    type: "text",
                    icon: _ctx.ArrowLeft,
                    onClick: _ctx.$router.back,
                    class: "me-4"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("page.atras")), 1)
                    ]),
                    _: 1
                  }, 8, ["icon", "onClick"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "actions")
            ])
          ]),
          _: 3
        }, 8, ["breadcrumbs", "title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}